import React, { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom';
import AppRouter from './navigation/AppRouter';
import { Suspense } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import ProvideLang from "./i18n/ProvideLang";
import './i18n/i18n'
import ScrollToTop from './hooks/scrollToTop';

const App = () => {
  return (
    <Suspense fallback={null}>
      <ProvideLang>
        <BrowserRouter>
          <ScrollToTop />
          <AppRouter/>
        </BrowserRouter>
      </ProvideLang>
    </Suspense>
  )
}

export default App